<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="changes pa-8">
          <div v-html="compiledMarkdown"></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */

import marked from "marked";

const en = require("../../changes/en.md");
const de = require("../../changes/de.md");

export default {
  name: "Changes",
  data() {
    return {
      markdown: ""
    };
  },
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://alge-results.com/changes`
        }
      ]
    };
  },
  computed: {
    compiledMarkdown() {
      return marked(this.markdown);
    }
  },
  watch: {
    "$i18n.locale": function(newLocale, oldLocale) {
      this.setMarkdown(newLocale);
    }
  },
  methods: {
    setMarkdown(language) {
      switch (language) {
        case "de":
          this.markdown = de;
          break;
        default:
          this.markdown = en;
          break;
      }
    }
  },
  mounted() {
    this.setMarkdown(this.$i18n.locale);
  }
};
</script>

<style>
.changes hr {
  margin: 20px 0;
}
</style>
